import { auth, db } from '../firebase'; 
import { useState, useEffect } from 'react';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import '../Form/Form.css'

const Header = () => {
  const id = process.env.REACT_APP_COMPANY_ID;

  const [uid, setUid] = useState("");
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [usermail, setUsermail] = useState("");
  const [userId, setUserId] = useState("vish123");
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState(''); // State to store company logo URL

  // useEffect(() => {
  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setCompanyName(data.company_name);
  //       setCompanyLogo(data.company_logo); // Set the company logo URL from JSON data
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching bot_name:", error);
  //     });
  // }, [url]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setUid(currentUser.uid);
        setUserId(currentUser.uid);
        setUsername(currentUser.displayName);
        setUsermail(currentUser.email);
      }
  
      const querySnapshotNew = await getDocs(
        query(
          collection(db, "bot-templates"),
          where("company_id", "==", id)
        )
      );
  
      if (!querySnapshotNew.empty) {
        const botData = querySnapshotNew.docs[0].data(); // Assuming there's only one document
        setCompanyName(botData.company_name);
        setCompanyLogo(botData.company_logo);
      }
    });
  
    return () => unsubscribe();
  }, []);
  

  return (
    <div style={{ paddingLeft: "3vw", paddingTop: "3vw", width: "70%", marginLeft: '20%' }}>
      {user ? (
        <>
          <div style={{ display: "flex" }} className='brandname'>
            {companyLogo && <img src={companyLogo} style={{ borderRadius: "10px", width: "3vw" }} alt="Company Logo" />}
            &nbsp;
            <h2 style={{ fontWeight: "20px" }}>&nbsp;{companyName}</h2>
          </div>

          <div class="card text-bg-white mb-3" style={{ maxWidth: "max-content", boxShadow: "0 0 1px rgba(0, 0, 0, 0.528)", marginBottom: "5vh" }}>
            <div class="card-body">
              <p class="card-text">
                <div style={{ display: "flex" }}>
                  <h5 style={{ color: "#14183e" }}>{username} &nbsp;&nbsp;</h5>
                  <p>|&nbsp;&nbsp; {usermail}</p>
                </div>
                <div style={{ display: "flex", fontSize: "0.8rem", color: "grey" }}>
                  <p>User ID&nbsp;&nbsp;:&nbsp;&nbsp;</p>
                  <p>{uid}</p>
                </div>
              </p>

             
            </div>
          </div>
        </>
      ) : (
        <div style={{display:"flex", marginTop:"15%"}} className='brandname'>
    <div style={{width:"8vw", marginTop:"-5%"}}>
   <img src={companyLogo} style={{borderRadius:"10px", width:"100%"}}/>
   </div>
   
   </div>
      )}
    </div>
  );
};

export default Header;
